body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes highlightFade {
  0% {
    background-color: #e6f7ff !important;
  }

  100% {
    background-color: #f0f8ff !important;
    /* Very light blue */
  }
}

/* Highlight styles */
.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-highlighted>td {
  background-color: #e6f7ff !important;
  animation: highlightFade 3s ease-out forwards !important;
}

/* Hover styles for highlighted row */
.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-highlighted:hover>td {
  background-color: #d1eaff !important;
  /* Slightly darker on hover */
}

/* Hover styles for non-highlighted rows */
.ant-table-wrapper .ant-table-tbody>tr:hover>td {
  background-color: #f5f5f5 !important;
}

.table-striped-rows tr:nth-child(2n) td {
  background-color: #fbfbfb;
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #fbfbfb;
}